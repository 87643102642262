import axios, { AxiosRequestConfig } from "axios";
import { store } from "../redux/store";
import { dataConstants } from "../constants/data-constants";
import { resetUser } from "../redux/user/user.actions";
import { message } from "antd";
import packageJson from "../../package.json";

//Logout function after refresh token expired
const logoutFn = async () => {
  try {
    store.dispatch(resetUser());
    sessionStorage.clear();
  } catch (error) {
    console.log(error);
  }
};

// Call setUser on application startup to retrieve JWT from local storage if it exists
//Set token as axios common header
// export function setUser() {
//   const token = store.getState().user.userData.access_token;
//   if (token) {
//     // Store the JWT in local storage
//     // sessionStorage.setItem("accessToken", token);

//     // Set the JWT as the default authorization header for Axios requests
//     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
//     axios.defaults.headers.common["mode"] = "cors";
//     axios.defaults.headers.common["credentials"] = "include";
//     axios.defaults.headers.common["crossorigin"] = "true";
//   } else {
//     // Remove the JWT from local storage and default Axios authorization header
//     delete axios.defaults.headers.common["Authorization"];
//     // sessionStorage.removeItem("accessToken");
//   }
// }

//Axios request handler interceptor
axios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => {
    return Promise.reject(error);
  }
);

const requestHandler = async (request: AxiosRequestConfig) => {
  if (isHandlerEnabled(request)) {
    const userdata = store.getState().user;

    if (userdata.isLogged) {
      request.headers["Content-Type"] = "application/json";

      if (request.url === dataConstants.refreshTokenUrl) {
        const refreshToken = userdata.authData.refresh_token;
        request.headers["Authorization"] = `Bearer ${refreshToken}`;
      } else {
        const accessToken = userdata.authData.access_token;
        request.headers["Authorization"] = `Bearer ${accessToken}`;
      }
    }
    // Add the desired headers to the request
    request.headers["mode"] = "cors";
    request.headers["credentials"] = "include";
    request.headers["crossorigin"] = "true";
    request.headers["Api-Version"] = "1.2.0";
    request.headers["Client-Type"] = "web";
  }
  return request;
};

// Axios response handler interceptor
axios.interceptors.response.use(
  (res) => {
    // const originalConfig = res.config;
    // if (originalConfig.url === dataConstants.loginUrl) {
    //   setUser(res.data.data.access_token);
    // }

    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      originalConfig.url !== dataConstants.loginUrl &&
      originalConfig.url !== dataConstants.refreshTokenUrl &&
      err.response
    ) {
      // Access Token was expired
      if (
        (err.response.status === 401 || err.response.status === 422) &&
        originalConfig.url === dataConstants.refreshTokenUrl
      ) {
        await logoutFn();
        return Promise.reject(err);
      }
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        // const userdata = store.getState().user;

        // try {
        //   setUser();
        //   const rs = await axios.post(dataConstants.refreshTokenUrl);
        //   //set user to acces token
        //   setUser();
        //   //set user data for authenticaton

        //   store.dispatch(
        //     setUserData({
        //       ...userdata.userData,
        //       ...rs.data.data,
        //       ...{ accessToken: rs.data.data.access_token },
        //     })
        //   );

        //   return axios(originalConfig);
        // } catch (_error) {
        //   console.log(_error);
        //   return Promise.reject(_error);
        // }
      }
      if (
        (err.response.status === 500 ||
          err.response.status === 400 ||
          err.response.status === 503 ||
          err.response.status === 502 ||
          err.response.status === 404) &&
        err.response.message === ""
      ) {
        message.error("Something went wrong, Please try again later.");
      }
    }
    return Promise.reject(err);
  }
);

const isHandlerEnabled = (config: any) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
